import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"
import cennik from "../content/pricing.yaml"

const Cennik = () => (
  <Layout>
    <Seo title="Cennik" />
    <div className="container py-20">
      <h1 className="font-bold text-4xl text-secondary mb-4" style={{ marginLeft: '-5px'}}>Cennik</h1>
      <div className="border-b-8 border-secondary w-12 mb-12"></div>
      <div>
          {cennik.map((data, index) => {
              return (
                <div key={index} className="border-t border-gray-200 py-3 grid grid-cols-1 md:grid-cols-2">
                  <div className="mt-8 mb-4 md:mt-0 md:mb-0 font-bold text-primary text-xl">{ data.kategoria }</div>
                  <div className="text-body">
                    { data.lista.map((data, index) => {
                      return (
                        <div key={index} className="flex justify-between py-1 border-b border-gray-200 last:border-transparent">
                          <div className="mr-4">{data.nazwa}</div>
                          <div className="whitespace-nowrap">{data.cena}</div>
                        </div>
                      )
                    }) }
                  </div>
                </div>
              )
          })}
      </div>
    </div>
    <Contact greyBackground={true} />
  </Layout>
)

export default Cennik
